<template>
  <v-alert
    v-model="showHint"
    dismissible
    type="info"
    color="blue lighten-3"
    outline
    class="hint"
  >
    <span class="color-primary font-weight-medium">
      <slot />
    </span>
  </v-alert>
</template>

<script>
export default {
  data () {
    return {
      showHint: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.hint {
  padding: 7px 16px;
  background: rgba(227, 242, 253, 0.5);
}

.v-alert.v-alert--outline {
  border-color: transparent !important;
}
</style>
