import queryString from 'query-string'
import mustache from 'mustache'
import {
  sectionType,
  DEFAULT_SECTION_TEXT,
  DEFAULT_BUTTON_TITLE,
  DEFAULT_SECTION_EDM_TITLE
} from './sections'
import { getRecordDataUrl, getCompanyConfig, TEMPLATE_KEYS } from '@/const'
import headHtml from './html/head.html'
import textElementHtml from './html/textElement.html'
import imageElementHtml from './html/imageElement.html'
import ImageFullWidthHtml from './html/imageFullWidth.html'
import Image2ColumnHtml from './html/image2Column.html'
import Image3ColumnHtml from './html/image3Column.html'
import buttonElementHtml from './html/buttonElement.html'
import titleElementHtml from './html/titleElement.html'
import sectionEndingHtml from './html/sectionEndingElement.html'
import { htmlToElement } from '@/utils/html'
const {
  TextElement,
  ImageElement,
  ImageFullWidth,
  Image2Column,
  Image3Column,
  ButtonElement,
  HeaderElement
} = sectionType

const getClickTrackingUrl = params => getTrackingUrl('click', params)

const getOpenTrackingUrl = params => getTrackingUrl('open', params)

const getTrackingUrl = (type, { url, content }) => {
  // FIXME: Using global variable is not a good practice, fixme if can
  return `${
    getRecordDataUrl().recordDataUrl[window.env]
  }?${queryString.stringify({
    service: 'CRM',
    trackingType: type,
    type: 'edm',
    function: 'redirect',
    userId: '{{userId}}',
    taskId: '{{taskId}}',
    jobId: '{{jobId}}',
    segmentId: '{{segmentId}}',
    groupId: '{{groupId}}',
    campaignId: '{{campaignId}}',
    templateId: '{{templateId}}',
    url,
    content
  })}`
}

const getUrl = (link, { defaultUrl = null } = {}) => {
  if (!link || typeof link !== 'object') {
    throw new Error('Link is required and must be an object')
  }
  if (defaultUrl == null) {
    defaultUrl = getCompanyConfig({ env: window.env }).defaultLink.officialUrl
  }
  switch (link.type) {
    case 'lucid_marketplace':
      return `{{${TEMPLATE_KEYS.LUCID_SID}:${link.sid}}}`
    case 'surveyUrl':
      return link.isPaidSurvey
        ? `{{${TEMPLATE_KEYS.SURVEY_URL_PAID}:${link.surveyUrl}}}`
        : `{{${TEMPLATE_KEYS.SURVEY_URL}:${link.surveyUrl}}}`
    case 'url':
    default:
      return link.url || defaultUrl
  }
}

const replaceHrefInATag = (text = '') => {
  const parentDom = htmlToElement(text)
  return Array.from(parentDom.childNodes)
    .map((node, i) => {
      if (!node.tagName) return node.textContent
      if (node.tagName !== 'A') return node.outerHTML
      const linkJson = node.dataset.link
      const url = linkJson
        ? getUrl(JSON.parse(linkJson))
        : node.getAttribute('href')
      node.setAttribute(
        'href',
        getClickTrackingUrl({
          url,
          content: node.text
        })
      )
      node.removeAttribute('data-link')
      return node.outerHTML
    })
    .join('')
}

export const convertSectionToHtml = section => {
  const { defaultImage } = getCompanyConfig({ env: window.env })
  if (!section || typeof section !== 'object') {
    throw new Error('Section is required and must be an object')
  }
  if (
    typeof section.data !== 'object' ||
    (![HeaderElement, Image2Column, Image3Column].includes(section.type) &&
      typeof section.data.link !== 'object')
  ) {
    throw new Error('Invalid format of section.data')
  }
  const data = {
    link: {
      url: ''
    },
    img: '',
    text: '',
    color: '',
    ...section.data
  }
  switch (section.type) {
    case TextElement: {
      return mustache.render(textElementHtml, {
        content: replaceHrefInATag(section.data.text) || DEFAULT_SECTION_TEXT
      })
    }
    case ImageElement: {
      const content = data.img || defaultImage.banner
      return mustache.render(imageElementHtml, {
        imageLink: getClickTrackingUrl({
          content,
          url: getUrl(data.link)
        }),
        imageUrl: content
      })
    }
    case ImageFullWidth: {
      const content = data.img || defaultImage.banner
      return mustache.render(ImageFullWidthHtml, {
        imageLink: getClickTrackingUrl({
          content,
          url: getUrl(data.link)
        }),
        imageUrl: content
      })
    }
    case Image2Column: {
      const img0 = data[0].img || defaultImage.bannerSquare
      const img1 = data[1].img || defaultImage.bannerSquare
      return mustache.render(Image2ColumnHtml, {
        imageLink_0: getClickTrackingUrl({
          img0,
          url: getUrl(data[0].link)
        }),
        imageUrl_0: img0,
        imageLink_1: getClickTrackingUrl({
          img1,
          url: getUrl(data[1].link)
        }),
        imageUrl_1: img1
      })
    }
    case Image3Column: {
      const img0 = data[0].img || defaultImage.bannerSquare
      const img1 = data[1].img || defaultImage.bannerSquare
      const img2 = data[2].img || defaultImage.bannerSquare
      return mustache.render(Image3ColumnHtml, {
        imageLink_0: getClickTrackingUrl({
          img0,
          url: getUrl(data[0].link)
        }),
        imageUrl_0: img0,
        imageLink_1: getClickTrackingUrl({
          img1,
          url: getUrl(data[1].link)
        }),
        imageUrl_1: img1,
        imageLink_2: getClickTrackingUrl({
          img2,
          url: getUrl(data[2].link)
        }),
        imageUrl_2: img2
      })
    }
    case ButtonElement: {
      const content = data.text || DEFAULT_BUTTON_TITLE
      return mustache.render(buttonElementHtml, {
        buttonLink: getClickTrackingUrl({
          content,
          url: getUrl(data.link)
        }),
        buttonText: content,
        color: data.color || '#266BB7'
      })
    }
    default:
      return ''
  }
}

export const convertTemplateToHtml = sectionlist => {
  let html = '<html>'

  html += headHtml

  html += `<body>
    <style type="text/css">
    @media screen yahoo and (max-width: 600px) {
      .table-wrapper {
        padding: 15px !important;
      }

      .table-container {
        width: 100% !important;
      }

      .fullImage {
        width: 100% !important;
      }

      .columnImage {
        width: 100% !important;
        max-width: 100% !important;
        display: table !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }

      .header img {
        width: 110px !important;
      }

      .footer td {
        text-align: center !important;
      }

      .boxTop {
        height: 15px !important;
      }

      .boxBottom {
        height: 15px !important;
      }

      .contentBox {
      }

      .full-width {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      .combination-2 .textBox {
        width: 100% !important;
      }

      .combination-2 .imgBox {
        width: 100% !important;
        padding: 20px 0 0 0;
      }

      .combination-2 .imgBox img {
        width: 70% !important;
        margin: 0 auto !important;
        display: block !important;
      }

      .boxBottomTable-1 {
        width: 100% !important;
        padding: 0 !important;
      }

      .boxBottomTable-2 {
        width: 100% !important;
        padding: 15px 0 0 0;
      }

      .boxBottomTable-2 img {
        display: block !important;
      }

      .boxBottomTable-2 td {
        text-align: left !important;
      }

      .buttonAction {
        font-size: 14px !important;
        padding: 10px 20px !important;
      }

      .finmas-about {
        padding: 0 !important;
      }

      .mobileDisplayBlock {
        display: block !important;
        width: 100% !important;
      }

      .mobileHidden {
        display: none !important;
      }

      .download {
        margin-bottom: 10px !important;
        width: 100% !important;
      }

      .download-link img {
        width: 100% !important;
        height: auto !important;
      }

      .social-link {
        margin-bottom: 10px !important;
        width: 100% !important;
      }

      .cashalo-header {
        padding-bottom: 4px !important;
      }

      .finizi-header {
        padding-bottom: 8px !important;
      }

      .finizi-header img {
        width: 98px !important;
      }
    }
    </style>
<table width="100%" align="center" border="0" cellpadding="0" cellspacing="0"
  style="background-color: #ffffff; padding:30px 20px" class="table-wrapper">
  <tr>
    <td>
      <table width="100%" align="center" border="0" cellpadding="0" cellspacing="0"
        style="max-width: 600px; color: #454545; margin: 0 auto;" class="table-container">`

  html += getHeaderHtml()

  const greetingSec = sectionlist.find(({ type }) => type === HeaderElement)
  html += mustache.render(titleElementHtml, {
    headerText:
      (greetingSec && greetingSec.data.text) || DEFAULT_SECTION_EDM_TITLE
  })

  sectionlist.forEach(section => {
    html += convertSectionToHtml(section)
  })

  html += sectionEndingHtml
  html += getFooterHtml()

  html += `
        </table>
      </td>
    </tr>
  </table>
</body>
</html>`

  return html
}

export const getHeaderHtml = ({ isPreview = false } = {}) => {
  const { template, defaultImage, defaultLink } = getCompanyConfig({
    env: window.env
  })
  return mustache.render(template.header, {
    logoLink: isPreview
      ? defaultLink.officialUrl
      : getClickTrackingUrl({
        content: defaultImage.companyLogo,
        url: defaultLink.officialUrl
      }),
    logoImgUrl: defaultImage.companyLogo,
    openTrackingUrl: isPreview
      ? defaultImage.openTrackingImage
      : getOpenTrackingUrl({
        url: defaultImage.openTrackingImage
      })
  })
}

export const getFooterHtml = ({ isPreview = false } = {}) => {
  const { template, defaultLink, customerService } = getCompanyConfig({
    env: window.env
  })
  return mustache.render(template.footer, {
    customerServiceEmail: customerService.email,
    customerServicePhone: customerService.phone,
    googlePlayDownloadButton: isPreview
      ? defaultLink.googlePlay
      : getClickTrackingUrl({
        content: 'footer google play download button',
        url: defaultLink.googlePlay
      }),
    iosDownloadButton: isPreview
      ? defaultLink.appStore
      : getClickTrackingUrl({
        content: 'footer ios download button',
        url: defaultLink.appStore
      }),
    facebookButton: isPreview
      ? defaultLink.facebook
      : getClickTrackingUrl({
        content: 'footer social facebook button',
        url: defaultLink.facebook
      }),
    instagramButton: isPreview
      ? defaultLink.instagram
      : getClickTrackingUrl({
        content: 'footer social instagram button',
        url: defaultLink.instagram
      }),
    linkedinButton: isPreview
      ? defaultLink.linkedIn
      : getClickTrackingUrl({
        content: 'footer social linkedin button',
        url: defaultLink.linkedIn
      }),
    twitterButton: isPreview
      ? defaultLink.twitter
      : getClickTrackingUrl({
        content: 'footer social twitter button',
        url: defaultLink.twitter
      })
  })
}
